<template>
    <base-index
        title="Customer Pending Fee"
        :entities="entities"
        :columns="columns"
        @onPageChanged="onPageChanged"
        :config="config">

        <template #controls>
            <base-button
                @click="download"
                size="sm"
                type="neutral"><i class="fa fa-download"></i>Download</base-button>
        </template>

        <template #filters>
            <b-col sm="3" v-if="$store.getters['auth/isSuper']">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Branch <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getBranches"
                                placeholder="Pick Branch"
                                v-model="filter.branch_id"
                            >
                                <el-option v-for="option in branches"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.name"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Operation <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getReports"
                                placeholder="Pick Operation"
                                v-model="filter.operation_id"
                            >
                                <el-option v-for="option in operations"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="`${ option.name } (${ option.branch.name })`"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Customer <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                remote
                                clearable
                                @change="getReports"
                                :remote-method="getCustomers"
                                placeholder="Search Customer By Name Or Phone"
                                v-model="filter.customer_id"
                            >
                                <el-option v-for="option in customers"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <label class="form-control-label">Date</label>
                <base-input addon-left-icon="ni ni-calendar-grid-58" class="mt-0">
                    <flat-picker
                            @change="getReports"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="filter.date">
                    </flat-picker>
                </base-input>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Status <span class="text-danger">*</span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getReports"
                                placeholder="Status"
                                v-model="filter.is_clear"
                            >
                                <el-option v-for="option in hasPaidOptions"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="`${ option.value }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
        </template>

        <template #summary>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Total ($)</label>
                        <div class="has-label">
                            <el-input :value="totalUsd" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Total (៛)</label>
                        <div class="has-label">
                            <el-input :value="totalRiel" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
        </template>

        <template #cell_pending_usd="{ entity }">
            {{ entity.pending_usd && entity.pending_usd > 0 ? entity.pending_usd.toLocaleString() : 0 }}
        </template>

        <template #cell_pending_riel="{ entity }">
            {{ entity.pending_riel && entity.pending_riel > 0 ? entity.pending_riel.toLocaleString() : 0 }}
        </template>

        <template #actions="{ entity }">
            <el-tooltip placement="top" :content="`${ !entity.is_clear ? 'Unpaid' : 'Paid'}`">
                <base-button @click="showModal(entity)" size="sm" :type="!entity.is_clear ? 'warning' : 'primary'"><i class="fa fa-dollar-sign"></i></base-button>
            </el-tooltip>
        </template>

        <template #others>
            <modal-input-data @clearPendingFee="clearPendingFee" ref="modalInputData"></modal-input-data>
        </template>

    </base-index>
</template>
<script>
import BaseIndex from '@components/Custom/BaseIndex';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
    Select,
    Option,
} from 'element-ui'
import store from './store';
export default {
    data() {
        return  {
            filter: {
                operation_id: '',
                customer_id: '',
                branch_id: '',
                page: 1,
                date: new Date(),
                is_clear: null
            },
            columns: [
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Code',
                    key: 'code'
                },
                {
                    label: 'Total Delivery (USD)',
                    key: 'pending_usd'
                },
                {
                    label: 'Total Delivery (Riel)',
                    key: 'pending_riel'
                },
                {
                    label: 'Remark',
                    key: 'remark'
                },
            ],
            config: {
                showAddNew: false,
                hasActions: true,
                hasBasicActions: false,
                hidePaginate: true
            },
            selectedEntity: null,
            hasPaidOptions: [
                {
                    label: 'Paid',
                    value: true
                },
                {
                    label: 'Not Paid',
                    value: false
                }
            ],
        };
    },
    methods: {
        download() {
            store.dispatch('download', this.filter);
        },

        showModal(entity) {
            this.selectedEntity = entity;
            this.$refs.modalInputData.show({
                remark: entity.remark
            });
        },
        clearPendingFee(meta) {
            store.dispatch('clearPendingFee', {
                id: this.selectedEntity.customer_id,
                data: {
                    remark: meta.remark,
                    date: this.filter.date,
                    is_clear: !this.selectedEntity.is_clear
                }
            })
            .then(() => {
                this.toastSuccess('Paid Customer');
                this.getReports();
                this.$refs.modalInputData.hide();
            });
        },
        getCustomers(search) {
            store.dispatch('getCustomers', {search});
        },
        getReports() {
            store.dispatch('index', this.filter);
            store.dispatch('getTotalPendingFee', this.filter);
        },
        getBranches() {
            store.dispatch('getBranches')
        },
        onPageChanged(page) {
            this.filter.page = page;
            this.getReports();
        }
    },
    mounted() {
        this.getReports();
        this.getBranches();
        store.dispatch('getOperations');
    },
    computed: {
        entities() {
            return store.getters['entities'];
        },
        operations() {
            return store.getters['operations'].filter(operation => {
                return this.filter.branch_id ? operation.branch_id === this.filter.branch_id : true;
            });
        },
        branches() {
            return store.getters['branches'];
        },
        customers() {
            return store.getters['customers'];
        },
        totalPending() {
            return store.getters['totalPending'];
        },
        totalUsd() {
            return this.totalPending.total_usd !== undefined ? this.totalPending.total_usd > 0 ? new Intl.NumberFormat().format(this.totalPending.total_usd.toFixed(2)) : 0 : 0;
        },
        totalRiel() {
            return this.totalPending.total_riel !== undefined ? this.totalPending.total_riel > 0 ? new Intl.NumberFormat().format(this.totalPending.total_riel.toFixed(2)) : 0 : 0;
        },
    },
    watch: {
        'filter.date': function(val) {
            if (val) {
                this.getReports();
            }
        }
    },
    components: {
        BaseIndex,
        flatPicker,
        [Select.name]: Select,
        [Option.name]: Option,
        ModalInputData: () => import('./ModalInputData')
    }
}
</script>

<style lang="scss" scope>
    .cls-form input {
        color: #000 !important;
        font-weight: 600;
    }
</style>