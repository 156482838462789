import CustomerRequest from '@requests/customer_request';
import OperationRequest from '@requests/operation_request';
import ReportRequest from '@requests/report_request';
import BranchRequest from '@requests/branch_request';
import CustomerPendingFeeRequest from '@requests/customer_pending_fee_request';

export default {
    download(_, filter) {
        ReportRequest.downloadHasPaidDeliveryReport(filter);
    },
    index({ commit }, filter) {
        ReportRequest.hasPaidDeliveryReportAllData(filter)
                        .then((data) => {
                            commit('SET_REPORTS', data);
                        });
    },
    getOperations({ commit }) {
        OperationRequest.index().then(({ data }) => {
            commit('SET_OPERATIONS', data);
        });
    },
    getCustomers({ commit }, filter) {
        CustomerRequest.index(filter).then(({ data }) => {
            commit('SET_CUSTOMERS', data);
        });
    },
    getBranches({ commit }) {
        BranchRequest.index()
                    .then(({ data }) => {
                        commit('SET_BRANCHES', data);
                    });
    },
    clearPendingFee(_, { id, data }) {
        return CustomerPendingFeeRequest.clearPendingFee(id, data);
    },
    getTotalPendingFee({commit}, filter) {
        ReportRequest.getTotalPendingFee(filter).then(({data}) => {
            commit('SET_TOTAL_PENDING', data);
        });
    }
}